<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../assets/img/vekop/vekop-nagy.png"
                  alt="portfolio"
                />
              </div>

            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Pályázat</h3>
                <ul>
                  <li>Kedvezményezett neve: Globál Építő Korlátolt Felelősségű Társaság</li>
                  <li>A fejlesztés tárgya, célja::&nbsp;<span style="font-weight: bolder;">41.20 - Lakó- és nem lakó épület építése tevékenységet segítő fejlesztések
támogatása.</span></li>
                  <li>A projekt keretében az alábbi projektelemek valósulnak meg: 
                    <p>Eszközbeszerzés (technológiai fejlesztést eredményező)</p>
                    <p>Szigetelés (homlokzati és födém)</p>
                    <p>Nyílászáró csere</p>
                    <p>Energiatakarékos világítás korszerűsítés LED technikával</p></li>
                  <li>A szerződött támogatás összege: 35&nbsp;540&nbsp;104 Ft</li>
                  <li>A projekt tervezett befejezési dátuma: 2021. október 30.</li>
                  <li>A projekt azonosító száma: VEKOP-1.2.6-20-2020-01520</li>
                </ul>
                <h4>Az alábbi projektelemek valósulnak meg a projekt keretében:</h4>
                <p>Pályázatunk célja többek között egy energiatakarékosabb és digitalizációjában már fejlettebb
műszaki paraméterekkel rendelkező csarnokok fenntartása és üzemeltetése volt. A pályázatban
beszerzett eszközök (CASE 580ST típusú kotró-rakodó, Layher gurulóállványok) és az infrastrukturális
fejlesztés eredménytermék 1) célja és szinten további eredménye, hogy a cégünk az acélszerkezeti
gyártást télen is végezni tudja mostantól. További 2) eredmény egy energiatakarékosabb és
digitalizációjában már fejlettebb műszaki paraméterekkel rendelkező csarnokok fenntartása és
üzemeltetése. Cégünk építőmesteri- és épület gépészeti kivitelezéssel, javítással, karbantartással és
üzemeltetéssel foglalkozik. Ezen munkálatokhoz, minden esetben kivétel nélkül szükségünk van kotró-
rakodó gépre. Minden munkafolyamathoz nélkülözhetetlen. Leginkább az acélszerkezet gyártás
kapcsán a terület rendezéséhez, valamint a csatornázási, kábelfektetési munkálatokra használnánk,
ahol egy adott szélességű árkot kell kiásni vagy csatornát tisztítani, majd a kitermelt talajt a
szállítójárműre felrakni. Ház építésénél is kihasználható a gép teljes tudása. Kiemelhető vele a ház
vagy a kerítés alapja, villával a raklapos áruk (tégla, térkő stb.) mozgathatók, a nyitható rakodókanál
segítségével terepet lehet vele rendezni. Layher állványzat: az építő iparban egyre rövidebb
határidővel kell teljesítenünk a megrendelőink felé. Ahhoz, hogy megfelelő minőségű és gyors munkát
tudjunk végezni, nagyon fontos a korszerű tárgyi eszköz állomány. Cégünknek munkáját jelenleg
Layher állványrendszer használatával segítjük, ez az oka annak, hogy ezzel kompatibilis guruló
állványzatokat tervezünk ezen pályázat keretein belül beszerezni. Tehát ezen eszközök beszerzése a
cég szempontjából fejlesztő hatású volt, mivel a legyártott acélszerkezeteket gyorsabb tudjuk
felépíteni általuk. Így több megrendelést tudunk egy időben teljesíteni. Ennek hatására a cég átlagos
statisztikai létszámát is növelni tudjuk az egyidőben végzett munkamennyiség határidőben történő
kiszolgálása érdekében.</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../components/header/HeaderFourEpitonet";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
